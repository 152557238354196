const search = {
  tab: {
    sms: "SMSメッセージ",
    qr: "QRコード(イメージ)",
  },
  summary: {
    analysis: "分析結果",
    scan_at: "最近のスキャン日",
    status: "状態",
    tags: "タギング",
    issuer: "発行者",
    subject: "発行対象",
    not_before: "発行日",
    not_after: "満了日",
    version: "バージョン",
    judgment_basis: "最終判断根拠(By ‘Generative AI’)",
    analysis_by_engine: "エンジン別分析結果及び判断根拠",
    screenshot: "スクリーンショット",
    doamin_title: "ドメイン",
    domain: "ドメイン名",
    registered_date: "登録日",
    registrant: "登録者",
    registrant_address: "登録者アドレス",
    updated_date: "最近の更新日",
    expiration_date: "満了日",
    name_servers: "ネームサーバー",
    ip_address: "IPアドレス",
    nation: "国",
    host_name: "ホスト名",
    screenshot_none: "スクリーンショットの画像がありません。",
    host_ip_none: "ホストIP情報がありません。",
    domain_none: "ドメイン情報がありません。",
    certificate_none: "証明書情報がありません。",
    hash_none: "ハッシュ情報は存在しません。",
    certificate: "証明書",
    disclaimer_service:
      "本サービスが提供する情報は、法的判断や保証を含まず、該当URLの詐欺等の犯罪目的での使用有無に関する最終判断はユーザーの責任において行われるものとします。サービス利用過程で発生するいかなる損害や問題について、当社は法的責任を負いません。",
    summary: "概要",
    resources: "リソース",
    links: "リンク",
    classification: {
      phishing: "フィッシング",
      fraud: "詐欺",
      gambling: "ギャンブル",
      adult: "アダルト",
      malware: "マルウェア",
      illegal: "違法",
      normal: "正常",
      inaccessible: "アクセス不可",
      unknown: "不明",
      privacy: "個人情報",
    },
  },
  resources: {
    tips: "該当するハッシュファイルを含むDomain（URL）情報を表示します。",
  },
  scanning: {
    url_scanning: "URLをスキャンしています。",
    wait_a_moment: "しばらくお待ちください。",
  },
  sms: {
    scanresult: "結果を見る",
    time: "スキャン要求時間",
    classfication: "判断結果",
    status: "処理状況",
    status_scanning: "スキャン中",
    status_limit_exceeded: "制限超過",
    status_scan_failed: "スキャン失敗",
    status_scan_completed: "スキャン完了",
    url_limit: "認識されたURLは10個まで表示されます。",
    message:
      "SMS スキャンは一度に{maxUrls}のURLしかスキャンできません。制限を超えたURLは{reenter}してスキャンしてください。",
    maxUrls: "最大3つ",
    reenter: "検索ボックスに再入力",
    no_urls: "検索対象にURLが含まれていません。",
    empty_sms_description: "URLを含むSMSを検索してください。",
    home_button: "ホームへ",
    terms_content:
      "AskURLサービスのSMSスキャン機能使用時、スキャンする文章に個人情報が含まれている可能性があります。その場合、個人情報を削除してから文章を入力してください。",
    terms_header: "SMSスキャンサービスご利用案内",
  },
  scan_button: "スキャン",
};

export default search;
