const search = {
  tab: {
    sms: "SMS 문자",
    qr: "QR코드(이미지)",
  },
  summary: {
    analysis: "분석결과",
    scan_at: "최근 스캔 시간",
    status: "상태",
    tags: "태그",
    issuer: "발급 기관",
    subject: "발급 대상",
    not_before: "발급 날짜",
    not_after: "만료일",
    version: "버전",
    judgment_basis: "최종 판단 근거(By ‘Generative AI’)",
    analysis_by_engine: "엔진별 분석 결과 및 판단 근거",
    screenshot: "스크린샷",
    doamin_title: "도메인",
    domain: "도메인 이름",
    registered_date: "등록일",
    registrant: "등록자",
    registrant_address: "등록자 주소",
    updated_date: "최근 갱신일",
    expiration_date: "만료일",
    name_servers: "네임서버",
    ip_address: "IP 주소",
    nation: "국가",
    host_name: "호스트 이름",
    screenshot_none: "스크린샷 이미지가 없습니다.",
    host_ip_none: "호스트IP 정보가 없습니다.",
    domain_none: "도메인 정보가 없습니다.",
    certificate_none: "인증서 정보가 없습니다.",
    hash_none: "Hash 정보가 없습니다.",
    certificate: "인증서",
    disclaimer_service:
      "본 서비스에서 제공하는 정보는 법적 판단이나 보증을 포함하지 않으며, 해당 URL의 사기 등 범죄적 목적 사용 여부에 대한 최종 판단은 사용자의 책임에 따라 이루어져야 합니다. 서비스 이용 과정에서 발생하는 모든 손해나 문제에 대해 회사는 법적 책임을 지지 않습니다.",
    summary: "요약 정보",
    resources: "리소스",
    links: "링크",
    classification: {
      phishing: "피싱",
      fraud: "사기",
      gambling: "도박",
      adult: "성인",
      malware: "멀웨어",
      illegal: "불법",
      normal: "정상",
      inaccessible: "접근 불가",
      unknown: "알 수 없음",
      privacy: "개인정보",
    },
  },
  resources: {
    tips: "해당 해시 파일이 포함되어 있는 Domain(URL) 정보를 보여줍니다.",
  },
  scanning: {
    url_scanning: "URL을 스캔하고 있습니다.",
    wait_a_moment: "잠시만 기다려 주세요.",
  },
  sms: {
    scanresult: "결과 보기",
    time: "스캔 요청 시간",
    classfication: "판단 결과",
    status: "처리 상태",
    status_scanning: "스캔 중",
    status_limit_exceeded: "한도 초과",
    status_scan_failed: "스캔 실패",
    status_scan_completed: "스캔 완료",
    url_limit: "인식된 URL에서 10개까지만 표시합니다.",
    message:
      "SMS 검사는 {maxUrls}의 URL만 검사할 수 있습니다. 한도를 초과한 URL은 {reenter}하여 스캔해 주세요.",
    maxUrls: "한 번에 최대 3개",
    reenter: "검색창에 다시 입력",
    no_urls: "검색 대상에 포함된 URL이 없습니다.",
    empty_sms_description: "URL이 포함된 SMS를 검색해주세요.",
    home_button: "홈으로 가기",
    terms_content:
      "AskURL 서비스에서 ‘SMS 문자’ 스캔 기능 사용 시, 스캔하려는 문자 내에 불특정하게 개인정보가 포함될 수 있습니다. 이 경우, 개인정보를 제거하고 문자를 입력하여 주십시오.",
    terms_header: "SMS 스캔 서비스 이용안내",
  },
  scan_button: "스캔하기",
};

export default search;
